import { createEffect, createEvent, createStore, restore } from 'effector';
import { assoc, propEq } from 'ramda';
import UnityWebgl from 'unity-webgl';
import { trackExercise } from '../exercises';
import {
  initializeCamera,
  releaseMediaStream,
  startPredictionLoop,
  stopPredictionLoop,
} from '../pose';
import {
  cancelEncoding,
  downloadRecording,
  encodeRecording,
  startRecording,
  stopRecording,
} from '../recorder';
import { getUnityConfig } from './config';
import { noop } from 'rxjs';
import {
  moveCamera,
  setCalibrationProgress,
  toggleCameraOverlay,
} from '../camera';

export const setUnityPayloadUrl = createEvent<string>('set_unity_payload_url');

export const selectMinigame = createEvent<{
  gameId: string;
  gameModeId: string;
} | null>('select_minigame');

export const loadUnity = createEvent<HTMLCanvasElement>('load_unity');

export const sendUnityMessage = createEvent<{
  methodName: string;
  payload?: any;
  gameObject?: string;
}>('send_unity_message');

const loadingProgress = createEvent<number>('loading_progress');
export const gameInitialized = createEvent('game_initialized');
export const gameStarted = createEvent('game_started');
export const gameFinished = createEvent('game_finished');
export const gameLaunched = createEvent('game_lauched');

export const loadUnityFx = createEffect(([canvas]: [HTMLCanvasElement]) => {
  const instance = new UnityWebgl(getUnityConfig());

  instance.unityConfig.matchWebGLToCanvasSize = true;
  instance.unityConfig.devicePixelRatio = 1;

  instance.on('progress', loadingProgress);

  instance.create(canvas);

  return instance;
});

export const sendUnityMessageFx = createEffect(
  ([instance, methodName, payload, gameObject = 'WebInterop']: [
    UnityWebgl,
    string,
    any,
    string?
  ]) => {
    instance.send(gameObject, methodName, payload);
  }
);

export const setupEventHandlersFx = createEffect((instance: UnityWebgl) => {
  instance.on('GamesAppInitialized', gameInitialized);
  instance.on('GameLaunched', noop);

  instance.on('GameClosed', () => {
    selectMinigame(null);
  });

  instance.on('GameEnded', () => {
    gameFinished();
    releaseMediaStream();
    stopPredictionLoop();
  });

  instance.on('RequestCameraPermission', initializeCamera);
  instance.on('ReleaseCamera', releaseMediaStream);

  instance.on('InitializePoseDetector', () => {
    // TODO: refactor this mess
    setTimeout(() => {
      sendUnityMessage({
        methodName: 'PoseDetectorInitialized',
      });
    }, 1000);
  });

  instance.on('StartInference', () => {
    startPredictionLoop(false);
  });

  instance.on('StopInference', stopPredictionLoop);
  instance.on('TrackExercise', trackExercise);
  instance.on('StartVideoRecording', startRecording);
  instance.on('StopVideoRecording', stopRecording);
  instance.on('EncodeVideoRecording', encodeRecording);
  instance.on('CancelVideoEncoding', cancelEncoding);
  instance.on('DownloadVideoRecording', downloadRecording);
  instance.on(
    'MoveCamView',
    (posX?: number, posY?: number, width?: number, height?: number) => {
      moveCamera({ height, posX, posY, width });
    }
  );
  instance.on('SetCamViewFiller', (calibrationProgress: number) => {
    setCalibrationProgress(calibrationProgress);
  });
  instance.on('ToggleCameraOverlay', (value: boolean) => {
    toggleCameraOverlay(value);
  });
});

export type $UnityState = {
  progress: number;
  instance: UnityWebgl | null;
  payloadUrl: string;
  gameStarted: boolean;
  enableRecording: boolean;
  gameInitialized: boolean;
  gameLaunched: boolean;
};

export const $selectedMinigame = restore(selectMinigame, null);

export const $unity = createStore<$UnityState>({
  progress: 0,
  instance: null,
  payloadUrl: import.meta.env.VITE_GAME_BUILD_URL,
  gameStarted: false,
  enableRecording: false,
  gameInitialized: false,
  gameLaunched: false,
})
  .on(setUnityPayloadUrl, (state, url) => assoc('payloadUrl', url, state))
  .on(loadUnityFx.doneData, (state, instance) =>
    assoc('instance', instance, state)
  )
  .on(loadingProgress, (state, progress) => assoc('progress', progress, state))
  .on(setupEventHandlersFx.done, assoc('gameLaunched', true))
  .on(gameInitialized, assoc('gameInitialized', true))
  .on(gameStarted, (state) => assoc('gameStarted', true, state))
  .on(gameFinished, (state) => assoc('gameStarted', false, state))
  .on(gameLaunched, (state) => assoc('gameLaunched', true, state));

export const $isLoaded = $unity.map(propEq('progress', 1));
