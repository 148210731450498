import Papa from 'papaparse';
import Rosetta from 'rosetta';
import {
  createSignal,
  createContext,
  onMount,
  useContext,
  Accessor,
  Setter,
} from 'solid-js';

interface i18nContext {
  i18n: Accessor<Rosetta.Rosetta<unknown>>;
  availableLanguages: Accessor<string[]>;
  setI18N: Setter<Rosetta.Rosetta<unknown>>;
}

const i18nContext = createContext<i18nContext>();

export const useI18nContext = () => {
  const context = useContext(i18nContext);

  if (!context) {
    throw new Error('usei18nContext must be used within a i18nContextProvider');
  }

  return context;
};

const initI18n = (): Promise<{
  dictionaryEntries: {
    [x: string]: any;
  };
  availableLanguages: string[];
}> => {
  return new Promise((resolve) => {
    Papa.parse(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vQjnThIDRJ5abfAP1Lp84sB1nzlqJveLykca6clVLlJEy5jusUWlfN6S8q_gloRhlQgL-idR-VpLdXa/pub?output=csv',
      {
        header: true,
        download: true,
        skipEmptyLines: true,
        delimiter: ',',
        dynamicTyping: true,
        complete: function (results) {
          const data = results.data as {
            Key: string;
            En: string;
          }[];

          const payload: { [x: string]: any } = {};

          data.forEach((value) => {
            const { Key, ...languages } = value;

            Object.entries(languages).forEach(([key, value]) => {
              if (payload[key.toLowerCase()]) {
                payload[key.toLowerCase()] = {
                  ...payload[key.toLocaleLowerCase()],
                  [Key.toLowerCase()]: value ?? '',
                };
              } else {
                payload[key.toLowerCase()] = {
                  [Key.toLowerCase()]: value ?? '',
                };
              }
            });
          });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const languages = data.flatMap(({ Key, ...languages }) => {
            return Object.keys(languages).flatMap((value) =>
              value.toLowerCase()
            );
          });

          const availableLanguages = [...new Set(languages)];

          return resolve({
            dictionaryEntries: payload,
            availableLanguages: availableLanguages,
          });
        },
      }
    );
  });
};

const dictionary = await initI18n();

const I18nContextProvider = (props: { children?: any }) => {
  const [i18n, setI18N] = createSignal(Rosetta());
  const [availableLanguages, setAvailableLanguages] = createSignal<string[]>(
    []
  );

  onMount(async () => {
    const i18n = Rosetta(dictionary.dictionaryEntries);
    const languagePreference = getUserLanguagePreferences(
      dictionary.availableLanguages
    );

    i18n.locale(languagePreference);
    setI18N(i18n);
    setAvailableLanguages(dictionary.availableLanguages);
  });

  const getUserLanguagePreferences = (availableLanguages: string[]) => {
    const userLanguagePreference: string =
      navigator.language || (navigator as any).userLanguage;

    if (!userLanguagePreference) return 'en-gb';
    if (userLanguagePreference.length < 0) return 'en-gb';

    const language = userLanguagePreference.split('-')[0];
    if (!language) return 'en-gb';
    if (availableLanguages.includes(language)) {
      return language;
    }

    return 'en-gb';
  };

  return (
    <i18nContext.Provider value={{ i18n, availableLanguages, setI18N }}>
      {props.children}
    </i18nContext.Provider>
  );
};

export default I18nContextProvider;
