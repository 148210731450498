import { createEvent, createStore } from 'effector';
import { assoc } from 'ramda';

export type $Camera = {
  cameraPosition: {
    posX?: number;
    posY?: number;
    width?: number;
    height?: number;
  } | null;
  calibrationProgress: number;
};

export const moveCamera = createEvent<{
  posX?: number;
  posY?: number;
  width?: number;
  height?: number;
}>('move_camera');

export const setCalibrationProgress = createEvent<number>(
  'set_calibration_progress'
);

export const toggleCameraOverlay = createEvent<boolean>('toggle_overlay');

export const $camera = createStore<$Camera>({
  cameraPosition: null,
  calibrationProgress: 0,
})
  .on(moveCamera, (state, cameraPosition) =>
    assoc('cameraPosition', cameraPosition, state)
  )
  .on(setCalibrationProgress, (state, calibrationProgress) =>
    assoc('calibrationProgress', calibrationProgress, state)
  )
  .on(toggleCameraOverlay, (state, toggleValue) =>
    assoc('cameraOverlay', toggleValue, state)
  );
