import { ModelName } from '../pose/classifiers';

export enum Exercise {
  NoAction = 0,
  PushUp = 1,
  KneePushUp = 2,
  Situp = 3,
  Squat = 4,
  ElbowPlankHold = 5,
  LegRaise = 6,
  GluteBridge = 7,
  JumpingJack = 8,
  HighKnee = 9,
  StretchHold = 10,
  SquatHold = 11,
  ForwardLunge = 12,
  Burpee = 13,
  SideLunge = 14,
  GluteBridgeHold = 15,
  WalkOut = 16,
  PushUpArmRaise = 17,
  PalmPlank = 18,
  PalmPlankPushUp = 19,
  ArchUp = 20,
  AbTwist = 21,
  HalfBurpee = 22,
  FeetTapLeft = 23,
  FeetTapRight = 24,
  CrossFeetTapFront = 25,
  CrossFeetTapBack = 26,
  CrossPunch = 27,
  StandingCrunchKnee = 28,
  StandingCrunchLeg = 29,
  StandingKick = 30,
  PlankUpDown = 31,
  KickBack = 32,
  BicycleCrunch = 33,
  MountainClimber = 34,
  Windmill = 35,
  RunningDash = 36,
  HandRaise = 37,
}

type ExerciseConfig = {
  repetitionSequences: ModelName[][];
  confidences: number[];
  hold?: boolean;
};

export const EXERCISES = new Map<Exercise, ExerciseConfig>([
  [
    Exercise.NoAction,
    {
      repetitionSequences: [],
      confidences: [],
    },
  ],
  [
    Exercise.JumpingJack,
    {
      repetitionSequences: [['StandingFront', 'JumpingJackOut']],
      confidences: [0.4, 0.7],
    },
  ],
  [
    Exercise.Squat,
    {
      repetitionSequences: [['SquatDown', 'StandingFrontFlexible']],
      confidences: [0.5, 0.75],
    },
  ],
  [
    Exercise.PushUp,
    { repetitionSequences: [['PushDown', 'PushUp']], confidences: [0.6, 0.8] },
  ],
  [
    Exercise.KneePushUp,
    {
      repetitionSequences: [['KneePushDown', 'KneePushUp']],
      confidences: [0.6, 0.8],
    },
  ],
  [
    Exercise.HighKnee,
    {
      repetitionSequences: [
        ['StandingFrontFlexible', 'HighKneeLeft'],
        ['StandingFrontFlexible', 'HighKneeRight'],
      ],
      confidences: [0.2, 0.5],
    },
  ],
  [
    Exercise.SquatHold,
    {
      hold: true,
      repetitionSequences: [['SquatDown']],
      confidences: [0.5],
    },
  ],
  [
    Exercise.SideLunge,
    {
      repetitionSequences: [
        ['StandingFrontWide', 'SideLungeLeft'],
        ['StandingFrontWide', 'SideLungeRight'],
      ],
      confidences: [0.7, 0.7],
    },
  ],
  [
    Exercise.LegRaise,
    {
      repetitionSequences: [['LegRaiseDown', 'LegRaiseUp']],
      confidences: [0.5, 0.7],
    },
  ],
  [
    Exercise.Situp,
    {
      repetitionSequences: [['SitupDown', 'SitupUp']],
      confidences: [0.5, 0.7],
    },
  ],
  [
    Exercise.GluteBridge,
    {
      repetitionSequences: [['GluteBridgeDown', 'GluteBridgeUp']],
      confidences: [0.4, 0.7],
    },
  ],
  [
    Exercise.ElbowPlankHold,
    {
      hold: true,
      repetitionSequences: [['Plank']],
      confidences: [0.5],
    },
  ],
  [
    Exercise.StretchHold,
    {
      hold: true,
      repetitionSequences: [['StretchHands']],
      confidences: [0.7],
    },
  ],
  [
    Exercise.Burpee,
    {
      repetitionSequences: [['BurpeeDown', 'BurpeeUp']],
      confidences: [0.4, 0.7],
    },
  ],
  [
    Exercise.GluteBridgeHold,
    {
      hold: true,
      repetitionSequences: [['GluteBridgeUp']],
      confidences: [0.7],
    },
  ],
  [
    Exercise.WalkOut,
    {
      repetitionSequences: [
        ['StandingSide', 'BendForwardFlexible', 'WalkOutDown'],
      ],
      confidences: [0.4, 0.4, 0.7],
    },
  ],
  [
    Exercise.FeetTapLeft,
    {
      repetitionSequences: [['StandingFrontFlexible', 'FeetTapLeft']],
      confidences: [0.5, 0.5],
    },
  ],
  [
    Exercise.FeetTapRight,
    {
      repetitionSequences: [['StandingFrontFlexible', 'FeetTapRight']],
      confidences: [0.5, 0.5],
    },
  ],
  [
    Exercise.CrossFeetTapFront,
    {
      repetitionSequences: [
        ['StandingFrontFlexible', 'CrossFeetTapFrontLeft'],
        ['StandingFrontFlexible', 'CrossFeetTapFrontRight'],
      ],
      confidences: [0.5, 0.5],
    },
  ],
  [
    Exercise.CrossFeetTapBack,
    {
      repetitionSequences: [
        ['StandingFrontFlexible', 'CrossFeetTapBackLeft'],
        ['StandingFrontFlexible', 'CrossFeetTapBackRight'],
      ],
      confidences: [0.5, 0.5],
    },
  ],
  [
    Exercise.CrossPunch,
    {
      repetitionSequences: [
        ['StandingFrontBoxing', 'PunchLeft'],
        ['StandingFrontBoxing', 'PunchRight'],
      ],
      confidences: [0.2, 0.5],
    },
  ],
  [
    Exercise.StandingCrunchKnee,
    {
      repetitionSequences: [
        ['StandingFrontFlexible', 'StandingCrunchKneeLeft'],
        ['StandingFrontFlexible', 'StandingCrunchKneeRight'],
      ],
      confidences: [0.5, 0.5],
    },
  ],
  [
    Exercise.StandingCrunchLeg,
    {
      repetitionSequences: [
        ['StandingFrontFlexible', 'StandingCrunchLegLeft'],
        ['StandingFrontFlexible', 'StandingCrunchLegRight'],
      ],
      confidences: [0.2, 0.5],
    },
  ],
  [
    Exercise.StandingKick,
    {
      repetitionSequences: [
        ['StandingFrontFlexible', 'StandingKickLeft'],
        ['StandingFrontFlexible', 'StandingKickRight'],
      ],
      confidences: [0.2, 0.5],
    },
  ],
  [
    Exercise.PlankUpDown,
    {
      repetitionSequences: [['Plank', 'PushUp']],
      confidences: [0.8, 0.8],
    },
  ],
  [
    Exercise.KickBack,
    {
      repetitionSequences: [
        ['KickBackMiddle', 'KickBackLeft'],
        ['KickBackMiddle', 'KickBackRight'],
      ],
      confidences: [0.4, 0.7],
    },
  ],
  [
    Exercise.BicycleCrunch,
    {
      repetitionSequences: [
        ['BicycleCrunchLeft', 'BicycleCrunchRight'],
        ['BicycleCrunchRight', 'BicycleCrunchLeft'],
      ],
      confidences: [0.5, 0.5],
    },
  ],
  [
    Exercise.MountainClimber,
    {
      repetitionSequences: [
        ['MountainClimberLeft', 'MountainClimberRight'],
        ['MountainClimberLeft', 'MountainClimberRight'],
      ],
      confidences: [0.5, 0.5],
    },
  ],
  [
    Exercise.Windmill,
    {
      repetitionSequences: [
        ['WindmillMiddle', 'WindmillLeft'],
        ['WindmillMiddle', 'WindmillRight'],
      ],
      confidences: [0.5, 0.5],
    },
  ],
  [
    Exercise.RunningDash,
    {
      repetitionSequences: [
        ['RunningMiddle', 'RunningLeft'],
        ['RunningMiddle', 'RunningRight'],
      ],
      confidences: [0.2, 0.5],
    },
  ],
  [
    Exercise.HandRaise,
    {
      repetitionSequences: [['HandRaiseLeft'], ['HandRaiseRight']],
      confidences: [0.5, 0.5],
      hold: true,
    },
  ],
]);
