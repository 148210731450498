import { sample } from 'effector';
import { pair } from 'ramda';
import { Subscription } from 'rxjs';
import {
  $repCounterSubscription,
  $trackedExercise,
  createRepCounterFx,
} from '.';
import { $usedClassifiers } from '../pose';
import { Exercise, EXERCISES } from './exercises';

sample({
  source: $trackedExercise,
  fn: (exercise) => {
    const exerciseConfig = EXERCISES.get(exercise);

    if (exerciseConfig === undefined) {
      return [];
    }

    return exerciseConfig.repetitionSequences.flat();
  },
  target: $usedClassifiers,
});

sample({
  clock: $trackedExercise,
  source: $repCounterSubscription,
  fn: pair<Subscription | null, Exercise>,
  target: createRepCounterFx,
});

sample({
  source: createRepCounterFx.doneData,
  target: $repCounterSubscription,
});
