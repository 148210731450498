import { createFFmpeg, fetchFile, FFmpeg } from '@ffmpeg/ffmpeg';
import { createEffect, createEvent, createStore, restore } from 'effector';
import { compose, concat, isNil, prop } from 'ramda';

export const loadFfmpeg = createEvent('load_ffmpeg');
export const startRecording = createEvent('start_recording');
export const stopRecording = createEvent('stop_recording');
export const videoRenderingLoop = createEvent<number>('video_rendering_loop');
export const videoCaptured = createEvent<Blob>('video_captured');
export const encodeRecording = createEvent('encode_recording');
export const cancelEncoding = createEvent('cancel_encoding');
export const downloadRecording = createEvent('download_recording');

export const loadFfmpegFx = createEffect(
  async (previousInstance: FFmpeg | null) => {
    const ffmpeg =
      previousInstance !== null
        ? previousInstance
        : createFFmpeg({
            corePath:
              'https://unpkg.com/@ffmpeg/core@0.11.0/dist/ffmpeg-core.js',
            log: true,
          });

    if (ffmpeg.isLoaded()) {
      cancelEncodingFx(ffmpeg);
    }

    await ffmpeg.load();

    return ffmpeg;
  }
);

function drawImageCover(
  ctx: CanvasRenderingContext2D,
  img: HTMLCanvasElement,
  x: number,
  y: number,
  w: number,
  h: number,
  offsetX = 0.5,
  offsetY = 0.5
) {
  if (arguments.length === 2) {
    x = y = 0;
    w = ctx.canvas.width;
    h = ctx.canvas.height;
  }

  // default offset is center

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0;
  if (offsetY < 0) offsetY = 0;
  if (offsetX > 1) offsetX = 1;
  if (offsetY > 1) offsetY = 1;

  const iw = img.width,
    ih = img.height,
    r = Math.min(w / iw, h / ih);
  let nw = iw * r, // new prop. width
    nh = ih * r, // new prop. height
    ar = 1;
  let cx, cy, cw, ch;

  // decide which gap to fill
  if (nw < w) ar = w / nw;
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh; // updated
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) cx = 0;
  if (cy < 0) cy = 0;
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
}

export const videoRenderingLoopFx = createEffect(
  ([canvas, gameCanvas, userVideo, watermark, timestamp]: [
    HTMLCanvasElement,
    HTMLCanvasElement,
    HTMLVideoElement,
    HTMLImageElement,
    number
  ]) => {
    const TARGET_FPS = 30;
    const frameTimespan = 1000 / TARGET_FPS;
    const now = Date.now();

    if (now - timestamp < frameTimespan) {
      requestAnimationFrame(() => videoRenderingLoop(timestamp));

      return;
    }

    const ctx = canvas.getContext('2d');

    if (isNil(ctx)) throw new Error("Can't get WebGL context!");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    drawImageCover(
      ctx,
      gameCanvas,
      0,
      canvas.height / 2,
      canvas.width,
      canvas.height / 2,
      0.5,
      0.5
    );
    // ctx.drawImage(
    //   gameCanvas,
    //   0,
    //   0,
    //   canvas.width,
    //   canvas.height / 2,
    //   0,
    //   canvas.height / 2,
    //   canvas.width,
    //   canvas.height / 2
    // );

    ctx.save();
    ctx.scale(-1, 1);
    ctx.drawImage(
      userVideo,
      canvas.width * -1,
      0,
      canvas.width,
      canvas.height / 2
    );
    ctx.restore();

    ctx.drawImage(watermark, 24, 1120);

    videoRenderingLoop(now);
  }
);

export const startCaptureFx = createEffect((recorder: MediaRecorder) => {
  recorder.ondataavailable = compose(videoCaptured, prop('data'));

  recorder.start();
});

export const stopCaptureFx = createEffect((recorder: MediaRecorder) => {
  recorder.stop();
});

const downloadBgMusic = async () => {
  const bgMusicUrl = (await import('./PartyHub.aac?url')).default;

  const bgMusicBlob = await fetch(bgMusicUrl).then((resp) => resp.blob());

  return bgMusicBlob;
};

export const encodeRecordingFx = createEffect(
  async ([ffmpeg, recording]: [FFmpeg, Blob]) => {
    const bgMusic = await downloadBgMusic();

    const videoFile = await fetchFile(recording);
    const audioFile = await fetchFile(bgMusic);

    const timestamp = Date.now();

    const inputVideoType = recording.type === 'video/mp4' ? 'mp4' : 'webm';

    const inputVideo = `replay_${timestamp}.${inputVideoType}`;
    const inputAudio = 'bg.aac';
    const outputFile = `replay_${timestamp}.mp4`;

    ffmpeg.FS('writeFile', inputVideo, videoFile);
    ffmpeg.FS('writeFile', inputAudio, audioFile);

    const ffmpegCommandBase = [
      '-stream_loop',
      '-1',
      '-i',
      inputAudio,
      '-i',
      inputVideo,
    ];

    const ffmpegCommand =
      inputVideoType === 'mp4'
        ? concat(ffmpegCommandBase, ['-c:v', 'copy'])
        : concat(ffmpegCommandBase, [
            '-c:v',
            'libx264',
            '-r',
            '25',
            '-g',
            '15',
            '-preset',
            'ultrafast',
          ]);

    await ffmpeg.run(...ffmpegCommand, '-c:a', 'copy', '-shortest', outputFile);

    const data = ffmpeg.FS('readFile', outputFile);

    const encodedVideo = new Blob([data.buffer], { type: 'video/mp4' });

    ffmpeg.FS('unlink', outputFile);

    return encodedVideo;
  }
);

export const cancelEncodingFx = createEffect((ffmpeg: FFmpeg) => {
  ffmpeg.exit();
});

export const downloadRecordingFx = createEffect((recording: Blob) => {
  const url = URL.createObjectURL(recording);

  const saveLink = document.createElement('a');
  saveLink.href = url;
  saveLink.download = 'replay.mp4';

  saveLink.click();
});

export const $ffmpeg = restore(loadFfmpegFx.doneData, null);

export const $canvas = createStore(document.createElement('canvas'));
export const $capturedVideo = restore(videoCaptured, null).reset(
  cancelEncodingFx
);
export const $encodedVideo = restore(encodeRecordingFx, null).reset(
  cancelEncodingFx
);

export const $recorder = $canvas.map((canvas) => {
  canvas.width = 1080;
  canvas.height = 1920;
  canvas.getContext('2d'); // Firefox doesn't allow capturing stream before calling getContext
  const mediaStream = canvas.captureStream(30);

  const mimeTypes = ['video/webm', 'video/mp4'];

  const mimeType = mimeTypes.find(MediaRecorder.isTypeSupported);

  if (!mimeType) throw new Error('Unsupported mime type');

  const recorder = new MediaRecorder(mediaStream, {
    mimeType,
  });

  return recorder;
});

export const $videoRenderingLoopActive = createStore(false);
