import { createEffect, createEvent, createStore, restore } from 'effector';
import {
  props,
  map as _map,
  update,
  repeat,
  equals,
  inc,
  __,
  includes,
  zip,
  divide,
  KeyValuePair,
  uniq,
  append,
} from 'ramda';
import {
  filter,
  map,
  scan,
  from,
  mergeAll,
  sampleTime,
  Subscription,
  Observable,
} from 'rxjs';
import debug from 'debug';
import { agregatedConfidence$ } from '../pose';
import { Exercise, EXERCISES } from './exercises';
import { ModelName } from '../pose/classifiers';

const logger = debug('exercise');

export const trackExercise = createEvent<Exercise>('track_exercise');
export const $trackedExercise = restore(trackExercise, Exercise.NoAction);
const repCount = createEvent<number>('rep_count');

const createRepCounter = (exerciseName: Exercise) => {
  logger(Exercise[exerciseName], 'creating rep counter');
  const exercise = EXERCISES.get(exerciseName);

  if (!exercise) {
    throw new Error(`Unknown exercise: ${exerciseName}`);
  }

  // 3-dimensional array representing a successful repetition for an action sequence
  // E.g. for JumpingJack would look like
  // |Standing|JumpingJack|
  // |--------|-----------|
  // |  true  |   false   |
  // |  false |   true    |
  const repetitionMatrices: boolean[][][] = _map(
    (repetitionSequence) =>
      repetitionSequence.map((_, idx, actions) =>
        update(idx, true, repeat(false, actions.length))
      ),
    exercise.repetitionSequences
  );

  // Repetition matrices mapped to corresponding action sequences
  const labeledMatrices$: Observable<KeyValuePair<ModelName[], boolean[][]>> =
    from(zip(exercise.repetitionSequences, repetitionMatrices));

  // Repetition confidence stream
  // Creates a streams for each action sequence emmiting true when confidences form the repetition matrix
  // E.g. for HighKnee would look like
  //
  //                                           |--> buffered [Standing, HighKneeLeft]  == [[true, false], [false, true]] --|
  // {Standing, HighKneeLeft, HighKneeRight} --|                                                                           |--> true/false
  //                                           |--> buffered [Standing, HighKneeRight] == [[true, false], [false, true]] --|
  const confidence$ = labeledMatrices$.pipe(
    map((matrix) => {
      const [repetitionSequence, repetitionMatrix] = matrix;

      return agregatedConfidence$.pipe(
        map(props(repetitionSequence)),
        map(zip(exercise.confidences)),
        map(_map(([threshold, confidence]) => confidence >= threshold)),
        filter((userSequence) => includes(userSequence, repetitionMatrix)),
        scan((matrix: boolean[][], val) => {
          if (matrix.length >= repetitionMatrix.length) {
            return [val];
          }

          return uniq(append(val, matrix));
        }, []),
        map(equals(repetitionMatrix))
      );
    }),
    mergeAll(exercise.repetitionSequences.length),
    filter(equals(true))
  );

  // Repetition counter
  const reps$ = confidence$.pipe(scan(inc, 0));

  // Hold timer
  const secondsHeld$ = confidence$.pipe(
    sampleTime(100),
    scan(inc, 0),
    map(divide(__, 10))
  );

  const counter$ = exercise.hold ? secondsHeld$ : reps$;

  return counter$;
};

export const createRepCounterFx = createEffect(
  ([oldSubscription, exercise]: [Subscription | null, Exercise]) => {
    oldSubscription?.unsubscribe();
    const repCounter$ = createRepCounter(exercise);

    const subscription = repCounter$.subscribe(repCount);

    return subscription;
  }
);

export const $repCount = restore(repCount, 0).reset(trackExercise);

export const $repCounterSubscription = createStore<Subscription | null>(null);
