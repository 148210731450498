import { Route, Router, Routes } from '@solidjs/router';
// import debug from 'debug';
import { lazy, Show } from 'solid-js';
import I18nContextProvider from './context/i18nContext';
import { isIOS, isMobile } from './utils';

const Landing = lazy(() => import('./Landing'));
const Kids = lazy(() => import('./pages/kids'));
const Ces = lazy(() => import('./Ces'));
const Game = lazy(() => import('./Game'));
const RepCounterTest = lazy(() => import('./RepCounterTest'));
const KidsPrivacy = lazy(() => import('./pages/kids-privacy'));

function App() {
  // debug.enable('pose,exercise,unity,fetch');
  const isKids = import.meta.env.VITE_IS_KIDS;

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          component={() =>
            !isKids ? (
              <div class="h-screen w-screen">
                <Landing />
                {/* We load the game if device is not mobile, so it's always in a
              ready state */}
                <Show when={!isMobile() && !isIOS()}>
                  <Game />
                </Show>
              </div>
            ) : (
              <>
                <I18nContextProvider>
                  <Kids />
                </I18nContextProvider>
                <Show when={!isMobile() && !isIOS()}>
                  <Game />
                </Show>
              </>
            )
          }
        />
        {isKids && (
          <Route
            path="/privacy"
            component={() => (
              <I18nContextProvider>
                <KidsPrivacy />
              </I18nContextProvider>
            )}
          />
        )}
        <Route path="/ces" component={Ces} />
        <Route path="/test" component={RepCounterTest} />
      </Routes>
    </Router>
  );
}

export default App;
