import { isIOS } from '../../utils';

const poseConfigs = {
  // Mobile
  tfjs: {
    model: {
      runtime: 'tfjs',
      modelType: 'lite',
      enableSegmentation: false,
    },
    flags: {
      WEBGL_PACK: false,
      WEBGL_VERSION: 1,
      WEBGL_FLUSH_THRESHOLD: -1,
      WEBGL_CPU_FORWARD: true,
      WEBGL_FORCE_F16_TEXTURES: true,
      WEBGL_RENDER_FLOAT32_CAPABLE: true,
      WEBGL_RENDER_FLOAT32_ENABLED: true,
    },
    videoInput: {
      width: 360,
      height: 270,
      frameRate: {
        ideal: 30,
      },
    },
  },
  // Desktop
  mediapipe: {
    model: {
      runtime: 'mediapipe',
      modelType: 'lite',
      solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/pose',
      enableSegmentation: false,
    },
    flags: {
      WEBGL_PACK: false,
      WEBGL_VERSION: 2,
      WEBGL_FLUSH_THRESHOLD: -1,
      WEBGL_CPU_FORWARD: true,
      WEBGL_FORCE_F16_TEXTURES: true,
      WEBGL_RENDER_FLOAT32_CAPABLE: true,
      WEBGL_RENDER_FLOAT32_ENABLED: true,
    },
    videoInput: {
      width: 360,
      height: 270,
      frameRate: {
        ideal: 30,
      },
    },
  },
};

const getPoseConfig = () => {
  return isIOS() ? poseConfigs.tfjs : poseConfigs.mediapipe;
};

export { poseConfigs, getPoseConfig };
