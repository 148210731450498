import { FFmpeg } from '@ffmpeg/ffmpeg';
import { sample } from 'effector';
import { all, always, equals, pair, prop } from 'ramda';
import {
  $canvas,
  $recorder,
  $videoRenderingLoopActive,
  startCaptureFx,
  startRecording,
  stopCaptureFx,
  stopRecording,
  encodeRecordingFx,
  videoRenderingLoop,
  videoRenderingLoopFx,
  encodeRecording,
  downloadRecording,
  $encodedVideo,
  downloadRecordingFx,
  videoCaptured,
  $ffmpeg,
  loadFfmpeg,
  loadFfmpegFx,
  cancelEncodingFx,
  cancelEncoding,
} from '.';
import { $pose } from '../pose';
import { $selectedMinigame, $unity } from '../unity';

sample({
  clock: startRecording,
  source: $videoRenderingLoopActive,
  filter: equals(false),
  fn: always(true),
  target: $videoRenderingLoopActive,
});

sample({
  clock: $videoRenderingLoopActive,
  source: $recorder,
  filter: (_, videoRenderingLoopActive) => videoRenderingLoopActive === true,
  target: startCaptureFx,
});

sample({
  clock: startRecording,
  source: $recorder,
  filter: (recorder) => /webm/.test(recorder.mimeType),
  target: loadFfmpeg,
});

sample({
  clock: loadFfmpeg,
  source: $ffmpeg,
  target: loadFfmpegFx,
});

sample({
  clock: startCaptureFx,
  fn: () => Date.now(),
  target: videoRenderingLoop,
});

sample({
  clock: stopRecording,
  fn: always(false),
  target: $videoRenderingLoopActive,
});

sample({
  clock: stopRecording,
  source: $recorder,
  target: stopCaptureFx,
});

sample({
  clock: sample({
    clock: encodeRecording,
    source: videoCaptured,
  }),
  source: sample({
    source: $ffmpeg,
    filter: (ffmpeg): ffmpeg is FFmpeg => ffmpeg !== null,
  }),
  fn: pair<FFmpeg, Blob>,
  target: encodeRecordingFx,
});

sample({
  clock: cancelEncoding,
  source: $ffmpeg,
  // eslint-disable-next-line ramda/prefer-ramda-boolean
  filter: (_ffmpeg): _ffmpeg is FFmpeg => true,
  target: cancelEncodingFx,
});

sample({
  clock: downloadRecording,
  source: $encodedVideo,
  filter: (encodedVideo): encodedVideo is Blob => encodedVideo !== null,
  target: downloadRecordingFx,
});

sample({
  clock: videoRenderingLoop,
  source: {
    canvas: $canvas,
    userVideo: $pose.map(prop('videoElement')),
    gameCanvas: $unity.map((unity) => unity.instance?.canvasElement ?? null),
    watermark: $selectedMinigame.map((minigame) => {
      const watermark = new Image(400, 206);

      if (minigame?.gameModeId === 'WallGameCesMode') {
        watermark.src = './watermark_contest.png';
      } else {
        switch (minigame?.gameId) {
          case 'SprintGame':
            watermark.src = './watermark1.png';
            break;
          case 'WallGame':
            watermark.src = './watermark2.png';
            break;
          case 'SkiGame':
            watermark.src = './watermark3.png';
            break;
          default:
            console.warn('Game Id not found, defaulting to watermark1');
            watermark.src = './watermark1.png';
            break;
        }
      }

      return watermark;
    }),
    loopRunning: $videoRenderingLoopActive,
  },
  filter: ({ loopRunning, userVideo, gameCanvas }) =>
    all(equals(true), [loopRunning, userVideo !== null, gameCanvas !== null]),
  fn: (
    { canvas, gameCanvas, userVideo, watermark },
    timestamp
  ): Parameters<typeof videoRenderingLoopFx>[0] => [
    canvas,
    gameCanvas!,
    userVideo!,
    watermark,
    timestamp,
  ],
  target: videoRenderingLoopFx,
});
