import { calculators, Keypoint } from '@tensorflow-models/pose-detection';
import { sample, Unit } from 'effector';
import { mergeRight, objOf, reduce } from 'ramda';
import UnityWebgl from 'unity-webgl';
import {
  $selectedMinigame,
  $unity,
  gameInitialized,
  loadUnity,
  loadUnityFx,
  selectMinigame,
  sendUnityMessage,
  sendUnityMessageFx,
  setupEventHandlersFx,
} from '.';
import { $repCount } from '../exercises';
import {
  $agregatedConfidence,
  initializeCameraFx,
  initializePoseDetectorFx,
  releaseMediaStreamFx,
  tfLandmarks,
} from '../pose';
import { getPoseConfig } from '../pose/config';
import { $encodedVideo } from '../recorder';

sample({
  clock: loadUnity,
  source: $unity,
  fn: (unity, canvas): [HTMLCanvasElement, string] => [
    canvas,
    unity.payloadUrl,
  ],
  target: loadUnityFx,
});

sample({
  clock: sendUnityMessage,
  source: $unity,
  filter: (state) => state.progress === 1,
  fn: (state, message): Parameters<typeof sendUnityMessageFx>[0] => [
    state.instance!,
    message.methodName,
    message.payload,
    message.gameObject,
  ],
  target: sendUnityMessageFx,
});

sample({
  clock: gameInitialized,
  source: $selectedMinigame,
  filter: (selectedMinigame) => selectedMinigame !== null,
  fn: (selectedMinigame) => ({
    methodName: 'LoadGame',
    payload: selectedMinigame,
  }),
  target: sendUnityMessage,
});

sample({
  clock: selectMinigame,
  source: setupEventHandlersFx.done,
  fn: (_, selectedMinigame) => ({
    methodName: 'LoadGame',
    payload: selectedMinigame,
  }),
  target: sendUnityMessage,
});

sample({
  clock: loadUnityFx.doneData,
  filter: (unity): unity is UnityWebgl => unity !== null,
  target: setupEventHandlersFx,
});

const prepareLandmarks = (landmarks: Keypoint[]) => {
  type Landmark = {
    x: number;
    y: number;
    z: number;
    w: number;
  };

  const pairs = landmarks.map(({ x, y, z = 0, score = 0 }, idx) =>
    objOf<Landmark, string>(idx.toString(), { x, y, z, w: score })
  );

  return reduce<{ [key: string]: Landmark }, { [key: string]: Landmark }>(
    mergeRight,
    {},
    pairs
  );
};

sample({
  clock: tfLandmarks,
  fn: (pose) => {
    const { videoInput } = getPoseConfig();
    const landmarks = prepareLandmarks(pose.keypoints3D ?? []);

    const normalizedLandmarks = prepareLandmarks(
      calculators.keypointsToNormalizedKeypoints(pose.keypoints, {
        width: videoInput.width,
        height: videoInput.height,
      })
    );

    return {
      methodName: 'LandmarksReceived',
      payload: { landmarks, normalizedLandmarks },
    };
  },
  target: sendUnityMessage,
});

const forwardEvent = <T>(event: Unit<T>, interopMethodName: string) => {
  sample({
    clock: event,
    fn: (payload) => ({
      methodName: interopMethodName,
      payload,
    }),
    target: sendUnityMessage,
  });
};

forwardEvent(initializeCameraFx.done, 'CameraPermissionGranted');
forwardEvent(releaseMediaStreamFx.done, 'CameraReleased');
forwardEvent(initializePoseDetectorFx.done, 'PoseDetectorInitialized');
forwardEvent(
  $encodedVideo.updates.filterMap((payload) => {
    if (payload === null) return;

    return URL.createObjectURL(payload);
  }),
  'VideoEncodingReady'
);
forwardEvent($repCount, 'RepCount');
forwardEvent($agregatedConfidence, 'ClassificationResults');
