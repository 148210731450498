import { createEffect, sample } from 'effector';
import { all, always, equals, prop } from 'ramda';
import classifiers from './classifiers';
import {
  $pose,
  initializeCamera,
  initializeCameraFx,
  initializePoseDetector,
  initializePoseDetectorFx,
  predictionLoop,
  predictionLoopFx,
  releaseMediaStream,
  releaseMediaStreamFx,
  startPredictionLoop,
  $flipCanvasCtx,
  $rawVideoElement,
} from '.';
import { combineEvents } from 'patronum';

sample({
  clock: initializeCamera,
  source: $pose.map(prop('videoElement')),
  filter: (videoElement): videoElement is HTMLVideoElement =>
    videoElement !== null,
  target: initializeCameraFx,
});

sample({
  clock: releaseMediaStream,
  source: $pose.map(prop('mediaStream')),
  filter: (mediaStream): mediaStream is MediaStream => mediaStream !== null,
  target: releaseMediaStreamFx,
});

sample({
  clock: sample({
    clock: initializePoseDetector,
    source: initializePoseDetectorFx.pending,
    filter: equals(false),
  }),
  source: $pose,
  filter: (pose) => !pose.initialized,
  target: createEffect(async () => {
    classifiers.forEach(async ({ loadModelFx }) => await loadModelFx());

    await initializePoseDetectorFx();
  }),
});

sample({
  clock: [
    combineEvents({
      events: [
        initializePoseDetectorFx.doneData,
        initializeCameraFx.doneData,
        startPredictionLoop,
      ],
    }),
    startPredictionLoop,
  ],
  source: $pose,
  filter: (pose) => {
    return pose.detector !== null && pose.mediaStream !== null;
  },
  fn: () => Date.now(),
  target: predictionLoop,
});

sample({
  clock: startPredictionLoop,
  fn: always(Date.now()),
  target: predictionLoop,
});

sample({
  clock: predictionLoop,
  source: {
    pose: $pose,
    flipCtx: $flipCanvasCtx,
    videoElement: $rawVideoElement,
  },
  filter: ({ pose }) =>
    all(equals(true), [
      pose.predictionLoopRunning,
      pose.mediaStream !== null,
      pose.detector !== null,
    ]),
  fn: (
    { pose, videoElement, flipCtx },
    timestamp
  ): Parameters<typeof predictionLoopFx>[0] => [
    pose.detector!,
    videoElement,
    flipCtx,
    timestamp,
    pose.mirrorLandmarks,
  ],
  target: predictionLoopFx,
});
