import UnityWebgl from 'unity-webgl';

const baseUrl = import.meta.env.VITE_GAME_BUILD_URL || '';
const extension = '.br';

type IUnityConfig = ConstructorParameters<typeof UnityWebgl>[1];
/**
 * Use this for Webgl Production build. Set BASE_URL as undefined for local builds
 */
export const getUnityConfig = (): IUnityConfig => ({
  loaderUrl: baseUrl + '/Build/Game.loader.js',
  dataUrl: baseUrl + '/Build/Game.data' + extension,
  frameworkUrl: baseUrl + '/Build/Game.framework.js' + extension,
  codeUrl: baseUrl + '/Build/Game.wasm' + extension,
  streamingAssetsUrl: baseUrl + '/StreamingAssets',
  webglContextAttributes: {
    powerPreference: 'high-performance',
    preserveDrawingBuffer: true,
    alpha: false,
    antialias: false,
    stencil: false,
    depth: false,
  },
});
