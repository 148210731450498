import { render } from 'solid-js/web';
import debug from 'debug';
import 'tailwindcss/tailwind.css';
import { Router } from '@solidjs/router';
import './index.css';
import './models/init';
import App from './App';

const logger = debug('fetch');

const originalfetch = window.fetch;

window.fetch = function (url, data) {
  logger('url received', url);
  if (
    url.toString().indexOf('https://api.impakt-dev.com') === 0 ||
    url.toString().indexOf('https://api.impakt.com') === 0
  ) {
    // if (url.indexOf('http://localhost:3000') === 0) {
    data = { ...data, ...{ credentials: 'include' } };
  }
  return originalfetch(url, data);
};

render(
  () => (
    <Router>
      <App />
    </Router>
  ),
  document.getElementById('root') as HTMLElement
);
